<template>
  <b-card
      v-if="shipment && declaration"
      title="Customs declaration"
  >
    <b-row>
      <b-col
        v-if="declaration.previous_document_category"
        lg="3"
        md="4"
      >
        <label>Previous document category</label>
        <v-select
            v-model="declaration.previous_document_category"
            :options="previous_document_categories"
            :clearable="false"
            :disabled="disabled"
            :reduce="item => item.value"
            label="text"
            input-id="previous-document-category"
        />
      </b-col>
      <b-col
        lg="3"
        md="4"
      >
        <label>Previous document type</label>
        <v-select
            id="previous-document-type"
            v-model="declaration.previous_document_type"
            :options="previousDocumentTypes"
            :clearable="false"
            :disabled="disabled"
        />
      </b-col>
      <b-col
        lg="3"
        md="4"
      >
        <label>Previous document reference</label>
        <b-form-input
          v-model="declaration.previous_document_reference"
          id="previous-document-reference"
          :disabled="disabled"
          class="mr-0 ml-0"
        />
      </b-col>
    </b-row>
    <b-row v-if="declaration.status_info && declaration.status_info['errors']">
      <b-col>
        <label>Declaration status</label>
        <ul class="pl-2">
          <li
            v-for="(error, index) in declaration.status_info['errors']"
            :key="index"
          >
            {{error.message || 'error message is undefined'}}
          </li>
        </ul>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BCard,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import declarationDataTypeCache from '@/views/shipments/declarations/components/classes/declarationDataTypeCache'

export default {
  components: {
    BCard,
    vSelect,
    BRow,
    BCol,
    BFormInput,
  },
  created() {
    this.fetchPreviousDocTypes()
  },
  methods: {
    fetchPreviousDocTypes() {
      if (this.shipment) {
        const cachedData = declarationDataTypeCache().getCachedDataTypes(this.shipment.entry_country)
        if (cachedData === null) {
          declarationDataTypeCache().getDataTypes(this.shipment.entry_country, this)
            .then(response => {
              response.data.data.forEach(type => {
                this.previousDocumentTypes.push(type?.type)
              })
              declarationDataTypeCache().cacheDataTypes(this.previousDocumentTypes)
            })
        } else {
          this.previousDocumentTypes = cachedData
        }
      }
    },
  },
  data() {
    return {
      previousDocumentTypes: [],
      previous_document_categories: [
        { value: 'X', text: 'Summary Declaration' },
        { value: 'Y', text: 'Initial Declaration' },
        { value: 'Z', text: 'Previous Document' },
      ],
    }
  },
  props: {
    shipment: {
      type: Object,
      required: true,
    },
    declaration: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
